<template>
    <b-modal :title="$t('config.tables.edit_tables')" v-model="modalActive" no-close-on-backdrop size="lg">
        <template #default>
            <div>
                <validation-observer ref="validation">
                    <b-row>
                        <b-col cols="12" md="6" lg="4" v-for="(item, index) in tables" :key="item.id">
                            <b-card>
                                <b-form-group class="my-0">
                                    <b-button variant="danger" @click="remove(index)" size="sm" class="float-right">
                                        <feather-icon icon="Trash2Icon"/>
                                    </b-button>
                                </b-form-group>
                                <b-form-group :label="$t('form_data.name') + ' ' + (index + 1)">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.name') + ' ' + (index + 1)"  rules="required">
                                        <b-form-input v-model="item.name"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group :label="$t('config.tables.shape_type') + ' ' + (index + 1)">
                                    <validation-provider #default="{ errors }" :name="$t('config.tables.shape_type') + ' ' + (index + 1)" rules="required">
                                        <v-select v-model="item.shape_type" appendToBody label="name" :options="types" :reduce="type => type.id" :clearable="false"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-card>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </template>

        <template #modal-footer>
            <div class="w-100">
                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-button class="float-left" variant="secondary" @click="tables.push({id: id++, name: '', shape_type: 1, x: 0, y: 0, x_size: 1000, y_size: 1000, r: 1000, chairs: []})">
                                <feather-icon icon="PlusIcon" class="mr-50"/>
                                <span class="align-middle">{{ $t('general.add') }}</span>
                            </b-button>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-button class="float-right" variant="primary" @click="validate">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">{{ $t('config.tables.edit_tables') }}</span>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BCard, BRow, BCol} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BCard,
            BRow,
            BCol,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        props: {
            businessUnitRoomId: {
                type: String
            }
        },
        data() {
            return {
                modalActive: false,
                tables: [],
                types: [{id: 1, name: this.$t('config.tables.rectangle')}, {id: 2, name: this.$t('config.tables.circle')}],
                id: 0,
                required
            }
        },
        methods: {
            open() {
                this.loadData()
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) this.add()
                })
            },
            async add() {

                const tmpTables = JSON.parse(JSON.stringify(this.tables))

                for (const table of tmpTables) {
                    if (typeof (table.id) === 'number') table.id = ''
                }

                try {
                    const response = await this.$http.put(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`, tmpTables)
                    this.modalActive = false
                    this.$emit('itemEdited', response.data.id)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            remove(index) {
                this.tables.splice(index, 1)
            },
            async loadData() {
                try {
                    const response = await this.$http.get(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`)
                    this.tables = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            }
        }
    }
</script>
<style>
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>